<template>
  <base-card class="coming-soon">
    <div>
      <h2>Time Remaining (You)</h2>
      <div class="countdown">
        <div class="container-day">
          <h3 class="day">{{ CdayOutput }}</h3>
          <h3>Days</h3>
        </div>
        <div class="container-hour">
          <h3 class="hour">{{ ChourOutput }}</h3>
          <h3>Hours</h3>
        </div>
        <div class="container-minute">
          <h3 class="minute">{{ CminuteOutput }}</h3>
          <h3>Minutes</h3>
        </div>
        <div class="container-second">
          <h3 class="second">{{ CsecondOutput }}</h3>
          <h3>Seconds</h3>
        </div>
      </div>
    </div>
  </base-card>
  <base-card class="coming-soon">
    <div>
      <h2>Time Remaining (Me)</h2>
      <div class="countdown">
        <div class="container-day">
          <h3 class="day">{{ dayOutput }}</h3>
          <h3>Days</h3>
        </div>
        <div class="container-hour">
          <h3 class="hour">{{ hourOutput }}</h3>
          <h3>Hours</h3>
        </div>
        <div class="container-minute">
          <h3 class="minute">{{ minuteOutput }}</h3>
          <h3>Minutes</h3>
        </div>
        <div class="container-second">
          <h3 class="second">{{ secondOutput }}</h3>
          <h3>Seconds</h3>
        </div>
      </div>
    </div>
  </base-card>
</template>

<script>
export default {
  data() {
    return {
      second: 1000,
      minute: 60000,
      hour: 3600000,
      day: 86400000,
      dayOutput: 0,
      hourOutput: 0,
      minuteOutput: 0,
      secondOutput: 0,
      CdayOutput: 0,
      ChourOutput: 0,
      CminuteOutput: 0,
      CsecondOutput: 0,
    };
  },
  methods: {
    countdown() {
      const countdownUntil = new Date("January 13, 2022 00:00:00").getTime();
      const now = new Date().getTime();
      const gap = countdownUntil - now;

      const CcountdownUntil = new Date("January 12, 2022 19:00:00").getTime();
      const Cgap = CcountdownUntil - now;

      this.dayOutput = Math.floor(gap / this.day);
      this.hourOutput = Math.floor((gap % this.day) / this.hour);
      this.minuteOutput = Math.floor((gap % this.hour) / this.minute);
      this.secondOutput = Math.floor((gap % this.minute) / this.second);

      this.CdayOutput = Math.floor(Cgap / this.day);
      this.ChourOutput = Math.floor((Cgap % this.day) / this.hour);
      this.CminuteOutput = Math.floor((Cgap % this.hour) / this.minute);
      this.CsecondOutput = Math.floor((Cgap % this.minute) / this.second);
    },
  },
  created() {
    setInterval(() => this.countdown(), 1000);
  },
};
</script>

<style scoped>
* {
  margin: 0.69rem;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
}

h2 {
  font-size: 3.5rem;
}

.coming-soon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.countdown {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.day,
.hour,
.minute,
.second {
  font-size: 2.5rem;
}
</style>
