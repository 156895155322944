import { createRouter, createWebHistory } from "vue-router";

// import CoachDetail from "./pages/coaches/CoachDetail.vue";
import CoachesList from "./pages/coaches/CoachesList.vue";
// import CoachRegistation from "./pages/coaches/CoachRegistration.vue";
// import ContactCoach from "./pages/requests/ContactCoach.vue";
// import RequestsReceived from "./pages/requests/RequestsReceived.vue";
// import UserAuth from "./pages/auth/UserAuth.vue";
import NotFound from "./pages/NotFound.vue";

import store from "./store/index.js";

const CoachDetail = () => import("./pages/coaches/CoachDetail.vue");
const ContactCoach = () => import("./pages/requests/ContactCoach.vue");
const UserAuth = () => import("./pages/auth/UserAuth.vue");
const RequestsReceived = () => import("./pages/requests/RequestsReceived.vue");
const CoachRegistation = () => import("./pages/coaches/CoachRegistration.vue");

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { name: "home", path: "/", redirect: "/coaches" },
    {
      name: "coaches",
      path: "/coaches",
      component: CoachesList,
    },
    {
      name: "coach",
      path: "/coaches/:id",
      component: CoachDetail,
      props: true,
      children: [{ name: "contact", path: "contact", component: ContactCoach }],
    },
    {
      name: "requests",
      path: "/requests",
      meta: { requiresAuth: true },
      component: RequestsReceived,
    },
    {
      name: "register",
      path: "/register",
      meta: { requiresAuth: true },
      component: CoachRegistation,
    },
    { name: "auth", path: "/auth", meta: { requiresUnauth: true }, component: UserAuth },
    { name: "catchAll", path: "/:notFound(.*)", component: NotFound },
  ],
});

router.beforeEach((to, _, next) => {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next("/auth");
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next("/");
  } else {
    next();
  }
});

export default router;
