import getters from "./getters.js";
import actions from "./actions.js";
import mutations from "./mutations.js";

export default {
  state() {
    return {
      userId: null,
      token: null,
      // tokenExpiration: null,
      didAutoLogout: false,
    };
  },
  // getters: getters,
  // actions: actions,
  // mutations: mutations,

  // We can use JS shorthand (like with components) to reduce the above to just this:
  getters,
  actions,
  mutations,
};
